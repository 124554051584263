import 'bootstrap/dist/css/bootstrap.min.css';
import Intro from './components/Intro';
import Step from './components/Step';
import Review from './components/Review';
import Final from './components/Final';


function App() {

  


  return (
    <div>
      <Intro />
      <Step />
      <Review />
      <Final />
    </div>
  );
}

export default App;
