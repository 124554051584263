import React from 'react';
import './Step.css';
import img1 from '../assets/4.png';
import img2 from '../assets/2.png';
import img3 from '../assets/3.png';

const Step = props => {

    

    return (
        <section className="step">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img src={img1} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-6 my-auto">
                        <div className="card text-center">
                            <div className="card-body step1">
                                <h4>HIDRATACIJA I NJEGA</h4>
                                <p className="info mb-3">Aktivna hidratacija i njega obezbjeđuju ubrzanu zamjenu starih ćelija novima. Proces regeneracije gornjih slojeva kože je obnovljen.</p>
                                <p className="info"><strong>Ćelije dobijaju dovoljno HIDRATACIJE</strong></p>
                                <p className="info"><strong>Dodavanje VITAMINA</strong></p>
                                <p className="info"><strong>Ponovno uspostavljanje međućelijskih VEZA</strong></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-6">
                        <img src={img2} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-6 my-auto">
                        <div className="card text-center">
                            <div className="card-body step2">
                                <h4>INTENZIVNA ZAŠTITA KOŽE</h4>
                                <p className="info">✅ Molekule hijaluronske kiseline "hvataju" čestice vlage i prenose je do kože.</p>
                                <p className="info">✅ Molekule vode kombinovane sa hijaluronskom kiselinom prodiru do dubljih slojeva kože i njeguju ćelije.</p>
                                <p className="info">✅ Antioksidanti u sastavu BIOLIFT prodiru do najdubljih slojeva kože i ispunjavaju ćelije izbacujući toksine i slobodne radikale.</p>
                                <p className="info">✅ Oslobođena radikala, hidrirana koža počinje da proizvodi kolagen i elastin koji aktivno zatežu bore.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-6">
                        <img src={img3} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-6 my-auto">
                        <div className="card text-center">
                            <div className="card-body step1">
                                <h4>SIGURNO, PROVJERENO I EFIKASNO</h4>
                                <p className="info"><strong>✅ Za koga je BIOLIFT krema?</strong></p>
                                <p className="info">Biolift krema dizajnirana za <b>sve one koji žele da podmlade izgled kože</b>, osvježe i zategnu kožu lica, <b>umanje znakove starenja kože na prirodan i jednostavan način</b>. Prigodna formula za muskarce i zene, bez agresivnog mirisa i masnih tragova.</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );   
};

export default Step;