import React from "react";
import "./Form.css";

const Form = (props) => {
  return (
    <React.Fragment>
      <div className="orderBlock">
        <div className="card">
          <div className="card-header">
            <h2>Poručite sada!</h2>
          </div>
          <div className="card-body">
            <form action="thanks.php" method="post">
              <div className="form-group">
                <input
                  type="text"
                  name="ime"
                  placeholder="Ime i prezime"
                  minLength="3"
                  maxLength="25"
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <input
                  name="phone"
                  title="Unesite minimum 9 brojeva"
                  placeholder="Broj telefona"
                  pattern=".{9,}"
                  required
                  className="form-control"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <select className="form-control" name="cijenaSelect">
                  <option value="44.90">1kom - 44.90KM</option>
                  <option value="75">2kom - 75KM</option>
                  <option value="95">3kom - 95KM</option>
                </select>
              </div>
              <div className="text-center">
                <button name="submit" type="submit" className="btn btn-success">PORUČI SADA!</button>
              </div>
              <p className="description">
                Dostava u roku od 2 dana!
                <span>DOSTAVA JE BESPLATNA</span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Form;
