import ReactDOM from 'react-dom';
import Form from './Form';
import product from '../assets/5.png';
import './Intro.css';


const Intro = props => {

    function test() {
        let brojKorisnika = Math.floor(Math.random() * 10) + 1;
        ReactDOM.render(brojKorisnika, document.getElementById('test'));
    }

    setInterval(test, 4000);

 

    return (
        <section className="intro">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mb-4">
                        <img src={product} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-4 intro-box">
                        <h2 className="intro-text">PRIRODNA HIDRATANTNA KREMA SA EFEKTOM ZAGLAĐIVANJA</h2>

                        <p><span className="span">1.</span> <b>HIDRATACIJA</b><br />Aktivne komponente <b>PRODIRU</b> u epiderm. <b>BORE SE ZATEŽU</b>, vraća se zdrav ten</p>
                        <p><span className="span">2.</span> <b>NJEGA</b><br /><b>NJEGUJE KOŽU</b> vitaminima i mikronutrijentima. <b>STIMULIŠE</b> proizvodnju kolagena i elastina</p>
                        <p><span className="span">3.</span> <b>OBNAVLJANJE</b><br />Kolagen i elastin <b>ZATEŽU</b> bore. BIOLIFT <b>STVARA</b> ZAŽTITNU BARIJERU na površini kože.</p>
                    
                        <h5>Trenutno kupuje <span id="test" style={{color: 'red'}}>2</span> korisnika</h5>
                        <div className="text-center dugme">
                            <a href="#formular"><button className="btn btn-success">PORUČI SADA!</button></a>
                        </div>
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <Form />
                    </div>
                </div>
            </div>
            
        </section>
    );   
};

export default Intro;