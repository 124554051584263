import React from 'react';
import slika1 from '../assets/ins1.jpg';
import slika2 from '../assets/ins2.jpg';
import slika3 from '../assets/ins3.jpg';

const Review = () => {
    return(
        <div className="container pt-5 pb-5">
            <div className="row mb-5">
                <div className="col-md-12 text-center">
                    <h3>Naši klijenti</h3>
                    <div style={{display: 'block', width: '100px', height: '2px', backgroundColor: '#376f25',margin: '1rem auto 2rem'}}></div>
                    <p style={{fontSize: '14pt'}}>Brinemo o klijentima te smo Vam s toga osigurali samo najbolje iz prirode. <b>BIOLIFT krema ima odobrenje od akreditovanih ustanova Bosne i Hercegovine za kvalitet</b> te kao takva predstavalja siguran i pametan odabir koji garantuju prave rezultate.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-12 text-center">
                    <img src={slika1} className="img-fluid" alt="" />
                </div>
                <div className="col-md-4 col-12 text-center">
                    <img src={slika2} className="img-fluid" alt="" />
                </div>
                <div className="col-md-4 col-12 text-center">
                    <img src={slika3} className="img-fluid" alt="" />
                </div>
            </div>
        </div>
    );
};

export default Review;