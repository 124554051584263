import React from 'react';
import Form from './Form';
import image1 from '../assets/1.png';
import './Final.css';

const Final = props => {

    return (
        <section className="final" id="formular">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mb-4">
                        <img src={image1} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-4 intro-box my-auto">
                        <h2 className="intro-text">KAKO KORISTITI ?</h2>
                        <p>Kremu nanijeti na kožu u tankom sloju, <b>na prethodno očisceno lice</b>. Nanosi se blagim masažnim pokretima na <b>cijelo lice, vrat i dekolte ili samo na regije sklone nesavršenostima</b>. Za najbolje rezultate preporuka je da se <b>koristi dva puta dnevno na čistu kožu</b>.</p>
                        <p>Preko <b>5000</b> zadovoljnih kupaca širom Bosne i Hercegovine i Srbije!</p>
                    
                    </div>
                    <div className="col-md-4">
                        <Form />
                    </div>
                </div>
            </div>
        </section>
    );   
};

export default Final;